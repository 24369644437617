import '../Styles/header.css';
import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Header = ({ isMenuOpen, toggleMenu, detFunc }) => {
  //console.log(isMenuOpen);
  return (
    <header className="header">
      <Link to={'/'} className='logo'><img src='logo2.png' alt="Logo" /></Link>
      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
      <Link to={'/home'}>Home</Link>
      <Link to={'/about'}>For Business</Link>
      <Link to={'/booking'}>Book An Appointment</Link>
      <Link to={'/signup'} className='login-register'>Login/Register</Link>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <div className={`mobile-menu ${isMenuOpen ? 'expanded' : ''}`}>
      <Link to={'/home'}>Home</Link>
      <Link to={'/about'}>For Business</Link>
      <Link to={'/booking'}>Book An Appointment</Link>
      <Link style={{paddingBottom:'20px'}} to={'/signup'}>Login/Register</Link>
      </div>
    </header>
  );
};

export default Header;
