import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import '../Styles/Forbusiness.css'; 
const Forbusiness = () => {
    return (
        <div className="business-container">
            <img src={`${process.env.PUBLIC_URL}/business_photo.jpg`} alt="Business" className="business-image" />
            <div className="business-content">
                <h2>For Business</h2>
                <p className='business_details'>
                    Enhance Your Team's Well-Being And Productivity With Huraira Consultancy's Corporate Workshops. 
                    Our Experienced Counsellor Offers Engaging, Interactive Sessions Focused On Improving Communication, 
                    Resolving Conflicts, And Building Stronger Workplace Relationships. These Workshops Include Interactive 
                    Activities Designed To Improve Personal Lives And Overall Performance.
                </p>
                <h3>Contact</h3>
                <span style={{ fontSize: '20px' }}>
                        <p><strong>MD. Ashraf</strong></p>
                        <p>Head Of Operations</p>
                        <p>
                            <FaPhoneAlt className="phone-icon" /> 
                            <a className='phone_nb' href="tel:01975416878">01975-416878</a>
                        </p>
                        
                </span>
                <h3>Or Visit Our Office</h3>
               
                
            </div>
        </div>
    );
}

export default Forbusiness;
