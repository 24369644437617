import React from 'react';
import '../Styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Terms, Policies, and Privacy</h1>
      <p>Effective Date: 27/10/2024</p>
      
      <h2>Welcome to Huraira Consultancy</h2>
      <p>
        We are dedicated to providing professional counselling services to support your journey toward personal and relational well-being. This page outlines our core policies, terms, and how we handle your data to provide a secure and transparent experience on our website, hurairaconsultancy.com.
      </p>

      <h2>About Us</h2>
      <p>
        Huraira Consultancy offers individual and couple counselling services, focusing on personal development and relationship health. Our goal is to create a supportive space for growth, understanding, and positive change. For more information about our services and mission, please visit our website.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have questions about our services, policies, or your appointments, please reach out to us through the following:
      </p>
      <address>
        Huraira Consultancy<br />
        Email: info@hurairaconsultancy.com<br />
        Address: House 7, Road 2/C, Block J, Baridhara, Gulshan, Dhaka 1212
      </address>

      <h2>Terms and Conditions</h2>
      <p>By using our website and services, you agree to our terms and conditions, which include:</p>
      <div>
        <div>Following the booking process accurately to secure your appointment.</div>
        <div>Providing accurate and up-to-date personal information.</div>
        <div>Adhering to our cancellation and refund policies outlined below.</div>
        <div>Agreeing to receive communications related to your reservations or promotional material, if opted in.</div>
      </div>
      <p>Failure to comply with these terms may result in restriction or suspension of access to our services.</p>

      <h2>Privacy Policy</h2>
      <h3>Information We Collect</h3>
      <p>When booking a session, we may collect the following information:</p>
      <div>
        <div>Contact Information: Full name (as per NID), phone number.</div>
        <div>Appointment Details: Counselling type, preferred date and time slot, and appointment type (online or in person).</div>
        <div>Payment Information: Information required for transaction processing.</div>
      </div>

      <h3>How We Use Your Information</h3>
      <p>We use your information to:</p>
      <div>
        <div>Confirm and manage reservations.</div>
        <div>Send updates or promotional content.</div>
        <div>Improve customer support and respond to inquiries.</div>
      </div>
      <p>
        We do not sell or lease your information. We may share it with service providers who assist in operations or for legal requirements, if applicable.
      </p>

      <h3>Security of Your Information</h3>
      <p>
        We apply reasonable security practices to protect your data, though no online platform can guarantee absolute security.
      </p>

      <h3>Changes to Our Privacy Policy</h3>
      <p>
        Updates to this policy will be posted on this page. Please review periodically for any changes.
      </p>

      <h2>Refund Policy</h2>
      <p>Refunds are considered in cases where:</p>
      <div>
        <div>The counsellor must cancel the session due to unforeseen circumstances.</div>
        <div>Technical issues prevent the completion of online counselling sessions.</div>
      </div>
      <p>Refunds are processed to the original payment method within 7 business days. Please contact our support team for assistance.</p>

      <h2>Cancellation Policy</h2>
      <p>We understand that plans may change. To reschedule or cancel:</p>
      <div>
        <div><strong>72 Hours’ Notice:</strong> We require at least 72 hours’ notice for cancellations or rescheduling to ensure availability for other clients.</div>
        <div><strong>Late Cancellations:</strong> Cancellations made within less than 72 hours or missed appointments may be non-refundable.</div>
      </div>
      <p>To cancel or reschedule, please contact our support team directly by phone or email.</p>
    </div>
  );
};

export default PrivacyPolicy;
