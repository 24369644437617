import React, { useState } from "react";

import Header from "../Pages/header";

import Footer from "../Pages/footer";

import Login from "../Pages/login";


function BookingApis({detFunc}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} detFunc={detFunc} />
      <Login />
      <Footer />
    </div>
  );
}

export default BookingApis;
