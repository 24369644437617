import UserDashboard from "../Pages/UserDashboard";

function UserDashboardApis({detFunc}){

    return(<>
    <div>
        
         <UserDashboard detFunc={detFunc}/>
    </div>
   
    
    </>)
}
export default UserDashboardApis;