
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import UserDashboardApis from './Services/UserDashboardApis';
import PhoneAuthApis from './Services/PhoneAuthApis';
import ForBusinessApis from './Services/ForBusinessApis';
import BookingApis from './Services/BookingApis';
import PrivacypolicyApis from './Services/PrivacypolicyApis';
import { useState, useEffect } from 'react';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [det, setDet] = useState(1);

  const toggleMenu = (x) => {
    setDet(x);
  };

  //console.log(det);


  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<UserDashboardApis />} />
          <Route path="/home" element={<UserDashboardApis />} />
          <Route path="/signup" element={<PhoneAuthApis />} />
          <Route path="/booking" element={<BookingApis />} />
          <Route path="/about" element={<ForBusinessApis />} />
          <Route path="/privacy" element={<PrivacypolicyApis />} />
          <Route path="/resourses" element={<ForBusinessApis />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
