import React , {useEffect} from "react";
import "../Styles/footer.css";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    AOS.init({

      duration: 900,
      once: false, // animation happens every time the element comes into view
    });
  }, []);

  return (
    <footer className="footer">
      
      <div className="footer-content">
          <div className="footer-bg">
              <p className="address-header">Address :</p>
              <div className="address">
                <p data-aos="fade-left">5th Floor, House 7, Road 2/C, Block J</p>
                <p data-aos="fade-right">Baridhara, Gulshan, Dhaka - 1212</p>
              </div>
              <div className="address-link">
              <a href="https://www.google.com.bd/maps/place/Huraira+Consultancy/@23.7947785,90.4225076,16.99z/data=!4m14!1m7!3m6!1s0x3755c70e544f0ce9:0x527b85925a2d7bf7!2sHuraira+Consultancy!8m2!3d23.794797!4d90.4250752!16s%2Fg%2F11w1rcr5r1!3m5!1s0x3755c70e544f0ce9:0x527b85925a2d7bf7!8m2!3d23.794797!4d90.4250752!16s%2Fg%2F11w1rcr5r1?entry=ttu" >Open In Maps</a>
              </div>          
        </div>       
      </div><br></br>

      <div className="extraSection">
       <h3>© 2024 Huraira Consultancy All Rights Reserved</h3>
       <p><Link to={'/privacy'}>Privacy Policy</Link> &nbsp;&nbsp; <Link to={'/booking'}>Resources</Link></p>
       <p>A Concern of Butterfly Lighthouse</p>
       <p className="payment_method">We're using safe payment for <img src={`${process.env.PUBLIC_URL}/FOOTER-aamarPay 1.svg`} alt="payment" className="payment-image" /> </p>
      </div>
    </footer>
  );
};

export default Footer;
