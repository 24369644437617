import React, { useEffect, useState } from "react";

import Header from "./header";
import Footer from "./footer";
import MainSection from "./mainSection";

function App({detFunc}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  return (
    <div className="App">
      <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} detFunc={detFunc} />
      <MainSection />
      <Footer />
    </div>
  );
}

export default App;
