import React, { useState } from "react";
import Header from "../Pages/header";
import Footer from "../Pages/footer";
import PhoneAuth from "../Pages/PhoneAuth";
import axiosInstance from "../auth/axiosInstance"; 

function PhoneAuthApis({ detFunc }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [token, setToken] = useState(null); 
  const[loading, setLoading] = useState(false);
  const [det, setDet] = useState('');
  const [det1, setDet1] = useState('');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getOtp = async (phoneNumber) => {
    if (phoneNumber.length === 11 && phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.slice(1);
    }
    const phoneNumber1 = '+' + phoneNumber;
    try {
      setDet1('Loading...');
      const response = await axiosInstance.post('/otp', { phoneNumber1 });
      if (response.data.status === "SUCCESS") {
        setDet1('Otp Sent');
        return true; // Indicate success
      } else {
        setDet1('Failed. Check Phonenumber');
        return false; // Indicate failure
      }
    } catch (err) {
      setDet1('Failed. Check Phonenumber');
      console.error('Error sending OTP:', err.message);
      return false; // Indicate failure
    }
  };

  const verifyOtp = async (phoneNumber, otp) => {
    if (phoneNumber.length === 11 && phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.slice(1);
    }
    const phoneNumber1 = '+' + phoneNumber;
    try {
      setLoading(true);
      setDet('');
      const response = await axiosInstance.post('/verify-otp', { phoneNumber1, otp });
      localStorage.setItem('token', response.data.token);
      setLoading(false);
      setDet('success');
    } catch (err) {
      console.error('Error verifying OTP:', err.message);
      setLoading(false);
      setDet('Wrong Otp');
    }
  };
  
  return (
    <div className="App">
      <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} detFunc={detFunc} />
      <PhoneAuth getOtp={getOtp} verifyOtp={verifyOtp} loading={loading} det={det} det1={det1}/>
      <Footer />
    </div>
  );
}

export default PhoneAuthApis;
