import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Styles/mainSection.css';
import { Link } from 'react-router-dom';

const MainSection = () => {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration: 900,
      once: false, 
    });
  }, []);

  

  return (
    <div className="main-section">
      <div className="top">
        <div className="text">
          <p className="web-title">Huraira Consultancy</p>
          <p className="subheading">Guiding Your Heart</p>
          <div className="buttons">
            <p className="appointment-button" style={{ marginBottom: "1rem" }} data-aos="fade-up">
              <Link to={'/booking'}>
                <img src={`${process.env.PUBLIC_URL}/coffee.svg`} alt="Coffee" /> Book An Appointment
              </Link>
            </p>
            <a className="hotline" href="tel:01951271550" style={{ cursor: 'pointer' }} data-aos="fade-up" data-aos-delay="200">
              <img src={`${process.env.PUBLIC_URL}/call-calling.svg`} alt="Call icon" />Hotline: 01951271550
            </a>
          </div>
        </div>
        <div className="couple">
          <img src="couple.webp" alt="" />
        </div>
      </div>

      <div className="counselling">
        <Link to={'/booking'} className="individual-counselling">
          <h2>Individual Counselling</h2>
          <p>
            Our Dedicated Counsellor Provides Personalized, Confidential Support
            For Relationship Issues, Both Online And Offline. We Offer
            Affordable, Evidence-Based Guidance, Prioritizing Compassionate Care
            To Help You Achieve A Healthier, Happier Relationship. Take The
            First Step Towards Stronger, More Fulfilling Connections With Us.
          </p>
          <div className="counselling_bot">
            <p>Book An Appointment {">"}</p>
            <img className="double-love-icon" src={`${process.env.PUBLIC_URL}/single_love.png`} alt="Single love icon" />
          </div>
        </Link>

        <Link to={'/booking'} className="couple-counselling">
          <h2>Couple Counselling</h2>
          <p>
            We Offer Personalized, Confidential Couple Counselling Both Online
            And Offline. Our Dedicated Counsellor Helps You Improve
            Communication, Resolve Conflicts, And Deepen Your Connection.
            Affordable And Compassionate, Our Evidence-Based Approach Supports A
            Stronger, Healthier Relationship. Start Your Journey To A Happier
            Partnership With Us Today.
          </p>
          <div className="counselling_bot">
            <p>Book An Appointment {">"}</p>
            <img className="single-love-icon" src={`${process.env.PUBLIC_URL}/double_love.png`} alt="Double love icon" />
          </div>
        </Link>
      </div>

      <div className="profile" data-aos="fade-up">
        <div data-aos="fade-right">
          <img src="apu.webp" alt="Huraira Shishir" />
        </div>

        <div className="profile-info" data-aos="fade-left">
          <h2>Meet, Huraira Shishir</h2>
          <p>
            An Accomplished Professional With Two Decades Of Experience, Huraira
            Shishir Excels In The Realm Of Relationships. Alongside Managing An
            Online Matrimonial Platform, She Serves As A Dedicated Relationship
            Consultant. Embracing Simplicity And Fostering Respect, She Believes
            These Values Are Pivotal To Achieving Happiness And Success In Any
            Partnership.
          </p>
        </div>
      </div>
      <div className="wave" data-aos="fade-up">
        <img src="curve.png" alt="Wave" />
      </div>
    </div>
  );
};

export default MainSection;
